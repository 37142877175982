import BaseAxios from 'axios';

const axios = BaseAxios.create({
  /*  baseURL: 'http://localhost:3000/', */
  baseURL: 'https://api.akyoltransfermerkezi.com/',
  // baseURL: 'http://10.10.30.112:50000/',
  /* baseURL: 'https://akyoldovizapi.orgino.com.tr/', */
});

export default axios;
