import axios from './Axios';

const { token } = JSON.parse(localStorage.getItem('current_user')) || {};

/* 
@endpoins:
    - /auth/register ///-> new Account
    - /auth/login ///-> login
    - /transaction/addTransaction ///-> new Account
    - /auth/register ///-> new Account
    - /auth/register ///-> new Account
*/

const userLogin = async (email, password /* cashDesk */) => {
  const { data } = await axios.post('auth/login', {
    email,
    password,
    /*  cashDesk, */
  });
  return data;
};

const adminLogin = async (email, password) => {
  const { data } = await axios.post('auth/adminLogin', {
    email,
    password,
  });
  return data;
};

const userLogout = async () => {
  const { data } = await axios.post(
    'auth/logout',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

const userRegister = async (body) => {
  const { data } = await axios.post('auth/addUser', body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }); /* name, surname, email, password, phone, userType */

  return data;
};

const addTransaction = async (
  body = {
    currencyType: 'USD',
    transactionType: 0,
    exchangeAmount: 0,
    totalPrice: 0, // exchangeAmount ile kur ile çarpımı
  }
) => {
  const { data } = await axios.post('/transaction/addTransaction', body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const deleteTransaction = async (id) => {
  const { data } = await axios.delete(
    `/transaction/deleteTransaction?id=${id}`,

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

const getCurrency = async () => {
  const { data } = await axios.get('/transaction/getActiveCurrencies', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const addTransictionList = async (obj) => {
  const { data } = await axios.get(
    `/transaction/getTransactionList?date=${obj.date}`,
    {
      // for pagination page and limit
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

const getDeletedList = async (obj) => {
  const { data } = await axios.get(
    `/transaction/getDeletedList?page=${obj?.page}&limit=${obj?.limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

const getXlsxDaily = async (date) => {
  const { data } = await axios.get(
    `/transaction/getXlsxDaily?date=${date}`, // 2023-01-05
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

const getXlsxTotal = async ({ startdate, enddate }) => {
  const { data } = await axios.get(
    `/transaction/getXlsxTotal?dateStart=${startdate}&dateEnd=${enddate}`, // ?dateStart=2023-01-04&dateEnd=2023-01-06
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

const getCurrencies = async () => {
  const { data } = await axios.get(`/transaction/getCurrencies`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const getHistory = async () => {
  const { data } = await axios.get(`/transaction/getAdminTransactionList`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const updateCurrency = async (id, body) => {
  const { data } = await axios.put(
    `/transaction/updateCurrencyById?id=${id}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

const getBalances = async () => {
  const { data } = await axios.get(`/balance/getBalances`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const addBalance = async (body) => {
  // { cashDesk, currencyType, initialBalance }
  const { data } = await axios.post(`/balance/addCurrency`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const deleteBalance = async (id) => {
  const { data } = await axios.delete(`/balance/deleteCurrency?id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const addExtraBalance = async (body) => {
  const { data } = await axios.post(`/balance/addBalance`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const removeExtraBalance = async (body) => {
  const { data } = await axios.post(`/balance/removeBalance`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const changeQuantity = async (body) => {
  const { data } = await axios.put(`/transaction/updateProfit`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const getCashdesks = async () => {
  const { data } = await axios.get(`/auth/getCashdesks`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const AddCashdesks = async (body) => {
  const { data } = await axios.post(`/cashdesk/addCashdesk`, body, {
    // {"name":"test"}

    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const RemoveCashdesks = async (id) => {
  const { data } = await axios.delete(`/cashdesk/removeCashdesk?id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const startDay = async () => {
  const { data } = await axios.get(`/cashdesk/startDay`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const endDay = async () => {
  const { data } = await axios.get(`/cashdesk/endDay`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const isCashDeskOpen = async () => {
  const { data } = await axios.get(`/cashdesk/isCashDeskOpen`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const cashDeskBalances = async () => {
  const { data } = await axios.get(`/cashdesk`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const getCurrentTransaction = async () => {
  const { data } = await axios.get(`/cashdesk/getCurrentTransaction`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const setCurrentTransaction = async (body) => {
  const { data } = await axios.post(`/cashdesk/setCurrentTransaction`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const ScreenGetAll = async () => {
  const { data } = await axios.get(`/screen/getAll`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const getScreenById = async (id) => {
  const { data } = await axios.get(`/screen/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const deleteScreenById = async (id) => {
  const { data } = await axios.delete(`/screen/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const addScreen = async (bodyFormData) => {
  const { data } = await axios.post(`/screen/`, bodyFormData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};
const updateScreen = async (id, bodyFormData) => {
  const { data } = await axios.put(`/screen/${id}`, bodyFormData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

const currentTransaction = async (body) => {
  const { data } = await axios.post(`/screen/setCurrentTransaction`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
const currentTransactionGetter = async (id) => {
  const { data } = await axios.get(`screen/getCurrentTransaction/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const emptyCashdesks = async (id) => {
  const { data } = await axios.put(
    `/cashdesk/removeCashdeskOccupied/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

const getZRapor = async () => {
  const { data } = await axios.get(`/auth/zReport`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

const UpdateUser = async (id, password) => {
  const { data } = await axios.put(
    `/auth/changePassword?id=${id}`,
    {
      password,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};
const GetUser = async () => {
  const { data } = await axios.get(`/auth/getUsers?page=1&limit=100`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
const getDeleteRequests = async () => {
  const { data } = await axios.get(`/transaction/getDeleteRequestedList`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
const adminDelete = async (id) => {
  const { data } = await axios.delete(`/transaction/deleteTransactionAdmin?id=${id}`,  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
const DeleteUser = async (id) => {
  const { data } = await axios.delete(`/auth/removeUser?id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export {
  currentTransactionGetter,
  currentTransaction,
  updateScreen,
  addScreen,
  deleteScreenById,
  ScreenGetAll,
  getScreenById,
  userLogin,
  adminLogin,
  userRegister,
  addTransaction,
  addTransictionList,
  getCurrency,
  userLogout,
  deleteTransaction,
  getDeletedList,
  getXlsxDaily,
  getXlsxTotal,
  addBalance,
  getBalances,
  getCurrencies,
  updateCurrency,
  deleteBalance,
  addExtraBalance,
  removeExtraBalance,
  changeQuantity,
  getCashdesks,
  getHistory,
  AddCashdesks,
  RemoveCashdesks,
  startDay,
  endDay,
  isCashDeskOpen,
  cashDeskBalances,
  getCurrentTransaction,
  setCurrentTransaction,
  emptyCashdesks,
  getZRapor,
  UpdateUser,
  DeleteUser,
  GetUser,
  getDeleteRequests,
  adminDelete,
};
