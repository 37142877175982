import { combineReducers } from 'redux';
import settings, {loading} from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  loadingReducer: loading
});

export default reducers;
