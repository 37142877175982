import { getCurrentLanguage } from 'helpers/Utils';
import { CHANGE_LOCALE } from '../contants';

const INIT_STATE = {
  locale: getCurrentLanguage(),
};

const LoadingState = {
  loading: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };

    default:
      return { ...state };
  }
};

export const loading = (state = LoadingState, action) => {
  switch (action.type) {
    case true:
      return {
        ...state,
        loading: true
      }
    case false:
      return {
        ...state,
        loading: false
      }
    default:
      return {
        ...state
      }
  }
}